<template>
  <v-menu left bottom offset-y :close-on-content-click="(close = false)">
    <template v-slot:activator="{ on }">
      <v-btn class="ml-2"
             min-width="0"
             text
             active-class="light-blue darken-3"
             v-on="on">
        <v-icon>{{ icons.account }}</v-icon>
      </v-btn>
    </template>

    <v-list nav>
      <v-list-item link to="/profile">
        <v-list-item-avatar>
          <v-img :src="avatarImg" />
        </v-list-item-avatar>
        <v-list-item-content class="subtitle">
          <v-list-item-title class="title">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ user.email }}
          </v-list-item-subtitle>
          <v-list-item-subtitle :aria-label="user.company.name" class="subtitle">
            {{ user.company.name }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ user.company.cnpj }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <template v-for="(item, i) in profile">
        <v-divider v-if="item.divider" :key="`divider-${i}`" class="mb-2 mt-2" />
        <v-list-group v-else-if="item.items" :key="`item-${i}`" :prepend-icon="item.icon">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(el, i) in item.items" :key="`item-${i}`" @click="onMenuClick(el)">
            <v-list-item-icon>
              <v-icon>{{ el.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ el.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item v-else :key="`item-${i}`" @click="onMenuClick(item)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import * as AppUtils from '@plugins/app-utils';
import {
  ICON_ACCOUNT,
  ICON_BOOK_OUTLINE,
  ICON_EMAIL,
  ICON_EMAIL_SYNC,
  ICON_TOOLS,
  ICON_DOMAIN,
  ICON_CASH,
  ICON_ACCOUNT_BOX_MULTIPLE,
  ICON_ACCOUNT_TIE,
  ICON_TITLE,
  ICON_HELP_CIRCLE,
  ICON_LOGOUT,
} from '@constants/icons';

export default {
  name: 'DefaultAccount',

  data() {
    return {
      icons: {
        account: ICON_ACCOUNT,
        email: ICON_EMAIL,
      },
      user: {
        name: '',
        company: {
          name: '',
        },
      },
      profile: [
        {
          title: 'Configurações',
          icon: ICON_TOOLS,
          items: [
            {
              title: 'Preferências da Empresa',
              icon: ICON_DOMAIN,
              route: 'settings-company-listpreferences',
            },
            {
              title: 'Empresas',
              icon: ICON_DOMAIN,
              route: 'settings-companies-list',
            },
            {
              title: 'Assinaturas',
              icon: ICON_CASH,
              route: 'settings-subscriptions-list',
            },
            {
              title: 'Perfis de Acesso',
              route: 'settings-profiles-list',
              icon: ICON_ACCOUNT_BOX_MULTIPLE,
            },
            {
              title: 'Usuários Administradores',
              route: 'settings-admins-list',
              icon: ICON_ACCOUNT_TIE,
            },
            {
              title: 'Usuários',
              route: 'settings-users-list',
              icon: ICON_ACCOUNT,
            },
            {
              title: 'Layouts de Emails',
              route: 'settings-emaillayouts-list',
              icon: ICON_EMAIL,
            },
            {
              title: 'Histórico de Emails Automáticos',
              route: 'settings-emailssends-list',
              icon: ICON_EMAIL_SYNC,
            },
            {
              title: 'CNAB - Boletos Bancários',
              route: 'settings-cnab-list',
              icon: ICON_BOOK_OUTLINE,
            },
          ],
        },
        { divider: true },
        {
          title: 'Modelos de Documentos Fiscais',
          route: 'settings-invoices-list',
          icon: ICON_TITLE,
        },
        { divider: true },
        {
          title: 'Ajuda',
          route: '/settings/helpcenter',
          icon: ICON_HELP_CIRCLE,
        },
        { divider: true },
        { title: 'Sair', icon: ICON_LOGOUT, route: 'auth-logout' },
      ],
      avatarImg: '',
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    onMenuClick(item) {
      if (item.route) {
        this.$router.push({
          name: item.route,
        });
      }
    },
    getUserInfo() {
      const customer_id = localStorage.getItem('customer_id');
      if (typeof customer_id === 'string') {
        this.$api.Me.index().then((response) => {
          this.user = response.data;
          this.user.company.cnpj = AppUtils.formatCnpjCpf(this.user.company.cnpj);
          this.avatarImg = this.user.avatar_url || require('@assets/avatar.png');
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-menu__content--fixed {
  bottom: 0;
  top: unset !important;
}
.subtitle {
  max-width: 15vw;
}
</style>
