import Admins from './services/Admins';
import { Cnabs, CnabsCommands } from './pages/cnabs/service';
import Companies from './services/Companies';
import CompanyPreference from './services/CompanyPreference';
import { CompanyPreferencesCommands } from './pages/companypreferences/services';
import Customers from './services/Customers';
import { EmailsLayouts } from './pages/emaillayouts/services';
import { EmailsSentSystem, EmailsSentSystemCommands } from './pages/emailsentsystem/services';
import InvoiceCertificate from './services/InvoiceCertificate';
import InvoiceTemplates from './services/InvoiceTemplates';
import { InvoiceTemplatesCommands } from './services/InvoiceTemplates';
import Me from './services/Me';
import Profiles from './services/Profiles';
import ProfilesMenus from './services/ProfilesMenus';
import StatusChecks from './services/StatusChecks';
import Subscriptions from './services/Subscriptions';
import UserRoles from './services/UserRoles';
import { Users, UsersCommands } from './services/Users';

export { default as routes } from './routes';

export const globalServices = {
  Admins,
  Cnabs,
  CnabsCommands,
  Companies,
  CompanyPreference,
  CompanyPreferencesCommands,
  Customers,
  EmailsLayouts,
  EmailsSentSystem,
  EmailsSentSystemCommands,
  InvoiceCertificate,
  InvoiceTemplates,
  InvoiceTemplatesCommands,
  Me,
  Profiles,
  ProfilesMenus,
  StatusChecks,
  Subscriptions,
  UserRoles,
  Users,
  UsersCommands,
};
