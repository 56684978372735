import Vue from 'vue';
import _ from 'lodash';
import moment from 'moment';
import {
  formatBoolean, formatCep, formatCnpjCpf, formatCurrency, formatDate,
  formatNumber, getMaskText, getOptionText, getOptionValueByValue, modifyRef, replaceMask,
} from '@plugins/app-utils';
import DynamicService from '@sharedServices/dynamic/DynamicService';
import { ulid } from 'ulid';

const threatEmpty = (item, field) => (!item[field] || item[field].length === 0 ? {} : item[field]);

export const formatResponse = (invoice) => {
  invoice.invoice_number = invoice.invoice_number || '';
  invoice.contingency = threatEmpty(invoice, 'contingency');
  invoice.request_key = threatEmpty(invoice, 'request_key');
  invoice.response_protocols = threatEmpty(invoice, 'response_protocols');
  invoice.service = threatEmpty(invoice, 'service');
  invoice.tax = threatEmpty(invoice, 'tax');
  invoice.emitter = threatEmpty(invoice, 'emitter');
  invoice.buyer = threatEmpty(invoice, 'buyer');
  invoice.buyer_id = invoice.buyer_id ?? (invoice.buyer && invoice.buyer.register_id);
  if (invoice.buyer && invoice.buyer.cnpj_cpf) {
    invoice.buyer.cnpj_cpf_formatted = formatCnpjCpf(invoice.buyer.cnpj_cpf);
  }
  invoice.transporter = threatEmpty(invoice, 'transporter');
  invoice.transporter_id = invoice.transporter.transporter_id || null;
  invoice.justificaion = threatEmpty(invoice, 'justificaion');
  invoice.bill = threatEmpty(invoice, 'bill');
  invoice.bill.duplicates = invoice.bill.duplicates || [];
  invoice.export = threatEmpty(invoice, 'export');
  invoice.use_freight_formatted = formatBoolean(invoice.use_freight);
  invoice.is_costumer_formatted = formatBoolean(invoice.is_costumer);
  invoice.items = invoice.items || [];
  invoice.total_discount_formatted = formatCurrency(parseFloat(invoice.total_discount ?? 0));
  invoice.total_products_formatted = formatCurrency(parseFloat(invoice.total_products ?? 0));

  invoice.total_invoice = parseFloat(invoice.total_products ?? 0) + (invoice?.transporter?.use_freight ? parseFloat(invoice.total_freight) : 0);
  invoice.total_invoice_formatted = formatCurrency(parseFloat(invoice.total_invoice));
  invoice.total_note_formatted = formatCurrency(parseFloat(invoice.total_note));

  if (invoice.transporter) {
    invoice.transporter.freight_type_formatted = getOptionText('freight', invoice.transporter.freight_type);
  }
  invoice.bills_formatted = '';

  invoice.bill.duplicates.forEach((duplicate) => {
    duplicate.due_date_formatted = formatDate(duplicate.due_date);
    if (duplicate?.value) {
      duplicate.value_formatted = formatCurrency(duplicate.value || 0);
    }
    const number = `Número: ${duplicate.number}`;
    const dueDate = `Vencimento: ${duplicate.due_date_formatted}`;
    const value = `Valor: ${duplicate.value_formatted}`;
    const separator = invoice.bills_formatted ? ' / ' : '';
    invoice.bills_formatted += `${separator}${number} - ${dueDate} - ${value}`;
  });

  if (invoice.bills_formatted) {
    invoice.bills_formatted = `Títulos: ${invoice.bills_formatted}`;
  }

  if (invoice?.response_errors && invoice.response_errors?.length > 0) {
    invoice.activeTab = 1;
  }

  const items = invoice.items.map((item) => {
    item.id = parseInt(item.id);
    const HAS_ICMS_ST_TAXES = item.icms_st;
    const HAS_ICMS_TAXES = item.icms;
    const HAS_IPI_TAXES = item.ipi;
    const HAS_PIS_TAXES = item.pis;
    const HAS_CEST = item.cest;
    const HAS_COFINS_TAXES = item.cofins;
    const HAS_SIMPLES_NACIONAL_TAXES = item.simple;
    const HAS_ICMS_FINAL_COSTUMER_TAXES = item.icms_final_costumer;

    item.buyer_uf = invoice?.buyer?.uf ?? '';

    item.product_id = item?.product_id ?? item?.product?.id ?? null;
    item.unit_value_formatted = formatCurrency(parseFloat(item.unit_value));
    item.discount_percentage_value_formatted = `${formatNumber(parseFloat(item.discount_percentage_value || 0))}%`;
    item.discount_value_formatted = formatCurrency(parseFloat(item.discount_value));
    item.total_value_formatted = formatCurrency(parseFloat(item.total_value));
    item.cest_id = (item.cest && item.cest_id) || null;
    item.unity_id = item?.unity_id ?? item?.unity?.id ?? null;

    item.cod_prod_anp = item.fuel?.cod_prod_anp ?? '';
    item.desc_prod_anp = item.fuel?.desc_prod_anp ?? '';
    item.uf_consumo = item.fuel?.uf_consumo ?? '';
    item.percentage_glp_opc = item.fuel?.percentage_glp_opc ?? 0;
    item.percentage_gnn_opc = item.fuel?.percentage_gnn_opc ?? 0;
    item.percentage_gni_opc = item.fuel?.percentage_gni_opc ?? 0;
    item.percentage_glp_opc_formatted = `${formatNumber(parseFloat(item.percentage_glp_opc))}%`;
    item.percentage_gnn_opc_formatted = `${formatNumber(parseFloat(item.percentage_gnn_opc))}%`;
    item.percentage_gni_opc_formatted = `${formatNumber(parseFloat(item.percentage_gni_opc))}%`;

    const cestItem = HAS_CEST ? item.cest : '';
    const cestId = HAS_CEST ? item.cest_id : '';

    const testTypeof = (value) => {
      if (typeof value === 'string') {
        return value.toString();
      }
      return value;
    };

    item.quantity = testTypeof(item.comercial_quantity);
    item.tributary_quantity = testTypeof(item.comercial_quantity);
    item.comercial_value = testTypeof(item.unit_value);
    item.tributary_value = testTypeof(item.unit_value);
    item.gross_value = testTypeof(item.total_value);

    item.barcode = item.comercial_ean ?? '';
    const returnedItem = {
      ...item,
      cest: cestItem,
      cest_id: cestId,
      icms: {
        ...item.icms,
        base: HAS_ICMS_TAXES ? item.icms.base : 0,
        aliquot: HAS_ICMS_TAXES ? item.icms.aliquot : 0,
        value: HAS_ICMS_TAXES ? item.icms.value : 0,
        base_formatted: HAS_ICMS_TAXES ? formatNumber(item.icms.base) : '0,00',
        aliquot_formatted: HAS_ICMS_TAXES ? formatNumber(item.icms.aliquot) : '0,00',
        value_formatted: HAS_ICMS_TAXES ? formatNumber(item.icms.value) : '0,00',
      },
      icms_st: {
        ...item.icms_st,
        base: HAS_ICMS_ST_TAXES ? item.icms_st.base : 0,
        aliquot: HAS_ICMS_ST_TAXES ? item.icms_st.aliquot : 0,
        value: HAS_ICMS_ST_TAXES ? item.icms_st.value : 0,
        base_formatted: HAS_ICMS_ST_TAXES ? formatNumber(item.icms_st.base) : '0,00',
        aliquot_formatted: HAS_ICMS_ST_TAXES ? formatNumber(item.icms_st.aliquot) : '0,00',
        value_formatted: HAS_ICMS_ST_TAXES ? formatNumber(item.icms_st.value) : '0,00',
      },
      ipi: {
        ...item.ipi,
        base: HAS_IPI_TAXES ? item.ipi.base : 0,
        aliquot: HAS_IPI_TAXES ? item.ipi.aliquot : 0,
        value: HAS_IPI_TAXES ? item.ipi.value : 0,
        base_formatted: HAS_IPI_TAXES ? formatNumber(item.ipi.base) : '0,00',
        aliquot_formatted: HAS_IPI_TAXES ? formatNumber(item.ipi.aliquot) : '0,00',
        value_formatted: HAS_IPI_TAXES ? formatNumber(item.ipi.value) : '0,00',
      },
      pis: {
        ...item.pis,
        base: HAS_PIS_TAXES ? item.pis.base : 0,
        aliquot: HAS_PIS_TAXES ? item.pis.aliquot : 0,
        value: HAS_PIS_TAXES ? item.pis.value : 0,
        base_formatted: HAS_PIS_TAXES ? formatNumber(item.pis.base) : '0,00',
        aliquot_formatted: HAS_PIS_TAXES ? formatNumber(item.pis.aliquot) : '0,00',
        value_formatted: HAS_PIS_TAXES ? formatNumber(item.pis.value) : '0,00',
      },
      cofins: {
        ...item.cofins,
        base: HAS_COFINS_TAXES ? item.cofins.base : 0,
        aliquot: HAS_COFINS_TAXES ? item.cofins.aliquot : 0,
        value: HAS_COFINS_TAXES ? item.cofins.value : 0,
        base_formatted: HAS_COFINS_TAXES ? formatNumber(item.cofins.base) : '0,00',
        aliquot_formatted: HAS_COFINS_TAXES ? formatNumber(item.cofins.aliquot) : '0,00',
        value_formatted: HAS_COFINS_TAXES ? formatNumber(item.cofins.value) : '0,00',
      },
      simple_national: {
        ...item.simple_national,
        base: HAS_SIMPLES_NACIONAL_TAXES ? item.simple.base : 0,
        aliquot: HAS_SIMPLES_NACIONAL_TAXES ? item.simple.aliquot : 0,
        value: HAS_SIMPLES_NACIONAL_TAXES ? item.simple.value : 0,
        base_formatted: HAS_SIMPLES_NACIONAL_TAXES ? formatNumber(item.simple.base || 0) : '0,00',
        aliquot_formatted: HAS_SIMPLES_NACIONAL_TAXES ? formatNumber(item.simple.aliquot || 0) : '0,00',
        value_formatted: HAS_SIMPLES_NACIONAL_TAXES ? formatNumber(item.simple.value || 0) : '0,00',
      },
      icms_final_costumer: {
        ...item.icms_final_costumer,
        base: HAS_ICMS_FINAL_COSTUMER_TAXES ? item.icms_final_costumer.base : 0,
        aliquot: HAS_ICMS_FINAL_COSTUMER_TAXES ? item.icms_final_costumer.aliquot : 0,
        value: HAS_ICMS_FINAL_COSTUMER_TAXES ? item.icms_final_costumer.value : 0,
        base_formatted: HAS_ICMS_FINAL_COSTUMER_TAXES ? formatNumber(item.icms_final_costumer.base) : '0,00',
        aliquot_formatted: HAS_ICMS_FINAL_COSTUMER_TAXES ? formatNumber(item.icms_final_costumer.aliquot) : '0,00',
        value_formatted: HAS_ICMS_FINAL_COSTUMER_TAXES ? formatNumber(item.icms_final_costumer.value) : '0,00',
      },
      quantity_formatted: formatNumber(item.quantity ?? 0),
      comercial_quantity_formatted: formatNumber(item.comercial_quantity ?? 0),
      unit_value_formatted: formatCurrency(parseFloat(item.unit_value || 0)),
      discount_value_formatted: formatCurrency(parseFloat(item.discount_value || 0)),
      discount_percentage_value_formatted: `${formatNumber(parseFloat(item.discount_percentage_value || 0))}%`,
      total_value_formatted: formatCurrency(parseFloat(item.total_value || 0)),
      tax_approximate_formatted: formatNumber(item.tax_approximate || 0),
      tax_approximate_percentage_formatted: formatNumber(item.tax_approximate_percentage || 0),
    };
    return returnedItem;
  });
  invoice.items = items;
  invoice.references = invoice.references || [];
  invoice.addresses = invoice.addresses || [];
  invoice.authorizers = invoice.authorizers || [];
  invoice.fields = invoice.fields || [];

  invoice.status_formatted = getOptionText('invoice_status', invoice.status);
  invoice.document_type_formatted = getOptionText('invoice_document_type', invoice.document_type);
  invoice.presence_indicator_formatted = getOptionText('presence_indicator', invoice.presence_indicator);
  invoice.buyer.taxpayer_type_formatted = getOptionText('tax_payer', invoice.buyer.taxpayer_type);

  invoice.delivery = {};
  invoice.pickup = {};

  if (invoice.addresses.length > 0) {
    let delivery = {};
    let pickup = {};
    invoice.addresses.map((address) => {
      if (address.type_logistic === 'delivery') {
        delivery = { ...address };
      } else if (address.type_logistic === 'withdrawal') {
        pickup = { ...address };
      }
    });
    invoice.delivery = delivery;
    invoice.pickup = pickup;
  }

  const formatProtocol = (protocol) => {
    const protocolDate = protocol.protocol_date ? formatDate(protocol?.protocol_date, true) : '';
    return protocol.protocol ? `${protocol.protocol} - ${protocolDate}` : '';
  };

  if (invoice.response_protocols.length > 0) {
    invoice.response_protocols.forEach((protocol, index) => {
      const actual = invoice?.response_protocols[index];
      const next = invoice?.response_protocols[index + 1];

      if (!next && protocol.situation === invoice.status) {
        invoice.protocol_formatted = formatProtocol(actual);
        return;
      }
      if (actual?.protocol_date > next?.protocol_date && protocol.situation === invoice.status) {
        invoice.protocol_formatted = formatProtocol(actual);
        return;
      }
      if (next?.protocol_date > actual?.protocol_date && protocol.situation === invoice.status) {
        invoice.protocol_formatted = formatProtocol(next);
        return;
      }
      invoice.protocol_formatted = formatProtocol(actual);
    });
  }

  // dates
  invoice.emission_date_hour = invoice.emission_date ? moment(invoice.emission_date).format('HH:mm') : '';
  invoice.emission_date_formatted = formatDate(invoice.emission_date);

  invoice.exit_date_hour = invoice.exit_date ? moment(invoice.exit_date).format('HH:mm') : '';
  invoice.exit_date_formatted = formatDate(invoice.exit_date);

  const delivery = invoice.addresses.find((addr) => addr.type_logistic === 'delivery');
  const withdrawal = invoice.addresses.find((addr) => addr.type_logistic === 'withdrawal');
  invoice.delivery = delivery;
  invoice.pickup = withdrawal;

  invoice.nature_operation_id = invoice.nature_operation_id || (invoice.nature_operation && invoice.nature_operation.id) || null;

  return invoice;
};

export const formatRequest = (invoice) => {
  if (invoice.emission_date) {
    invoice.emission_date = moment(invoice.emission_date).startOf('day').toDate();
    const emissionDate = moment(invoice.emission_date).startOf('day');
    const emissionDateFormatted = emissionDate.format('YYYY-MM-DD');
    invoice.emission_date = `${emissionDateFormatted} ${invoice.emission_date_hour}`;
  }
  if (invoice.exit_date) {
    invoice.exit_date = moment(invoice.exit_date).startOf('day').toDate();
    const exitDate = moment(invoice.exit_date).startOf('day');
    const exitDateFormatted = exitDate.format('YYYY-MM-DD');
    invoice.exit_date = `${exitDateFormatted} ${invoice.exit_date_hour}`;
  }

  const invalidInvoiceFields = [
    'to_s',
    'create_at',
    'updated_at',
    'contingency.to_s',
    'request_key.to_s',
    'response_protocols.to_s',
    'service.to_s',
    'tax.to_s',
    'emitter.to_s',
    'justificaion.to_s',
    'bill.to_s',
    'export.to_s',
    'items.to_s',
    'emission_date_hour',
    'emission_date_formatted',
    'exit_date_hour',
    'exit_date_formatted',
  ];

  const invalidItemFields = ['icms_st.to_s', 'simple.to_s', 'icms_st.created_at', 'simple.created_at', 'icms_st.updated_at', 'simple.updated_at'];

  const newInvoice = _.omit(invoice, invalidInvoiceFields);
  newInvoice.items = newInvoice.items || [];
  newInvoice.items = newInvoice.items.map((item) => {
    item = _.omit(item, invalidItemFields);

    // item.icms_attributes = item.icms;
    item.icms_st_attributes = item.icms_st;
    item.simple_attributes = item.simple;

    item.cest = item.cest || '';
    item.cest_id = item.cest_id || '';

    if (item.cest === '') {
      delete item.cest;
      delete item.cest_id;
    }

    item.tributary_quantity = item.comercial_quantity.toString();
    item.comercial_value = item.unit_value.toString();
    item.tributary_value = item.unit_value.toString();
    item.gross_value = item.total_value.toString();

    item.comercial_ean = item.barcode;
    item.tributary_ean = item.barcode;

    const product_type = item.product?.product_type?.to_s.replaceAll('í', 'i').toLowerCase().trim();
    if (product_type === 'combustivel') {
      item.fuel_attributes = {
        cod_prod_anp: item.cod_prod_anp,
        desc_prod_anp: item.desc_prod_anp,
        uf_consumo: item.uf_consumo,
        percentage_glp_opc: item.percentage_glp_opc,
        percentage_gnn_opc: item.percentage_gnn_opc,
        percentage_gni_opc: item.percentage_gni_opc,
      };
    }

    delete item.icms_st;
    delete item.simple;

    return item;
  });
  newInvoice.response_errors = newInvoice.response_errors || [];
  newInvoice.response_errors.forEach((item) => {
    item.created_at_formatted = formatDate(item.created_at);
  });

  newInvoice.cfop = newInvoice.nature_operation_name || '';
  newInvoice.presence_indicator = newInvoice.presence_indicator || getOptionValueByValue('presence_indicator', 'others');
  newInvoice.contingency_attributes = newInvoice.contingency || {};
  newInvoice.request_key_attributes = newInvoice.request_key || {};
  newInvoice.response_protocol_attributes = newInvoice.response_protocols || {};
  newInvoice.service_attributes = newInvoice.service || {};
  newInvoice.tax_attributes = newInvoice.tax || {};
  newInvoice.emitter_attributes = newInvoice.emitter || {};
  newInvoice.buyer_attributes = newInvoice.buyer || {};
  newInvoice.transporter_attributes = newInvoice.transporter || {};
  newInvoice.justificaion_attributes = newInvoice.justificaion || {};
  newInvoice.bill_attributes = newInvoice.bill || {};
  newInvoice.bill_attributes.duplicates_attributes = (newInvoice.bill && newInvoice.bill.duplicates) || [];
  delete newInvoice.bill_attributes.duplicates;
  newInvoice.export_attributes = newInvoice.export || {};

  newInvoice.items_attributes = newInvoice.items || [];
  newInvoice.references_attributes = invoice.references || [];
  newInvoice.addresses_attributes = newInvoice.addresses || [];
  newInvoice.authorizers_attributes = newInvoice.authorizers || [];
  newInvoice.fields_attributes = newInvoice.fields || [];

  if (newInvoice.destiny_location === '') delete newInvoice.destiny_location;
  modifyRef(invoice, newInvoice);
};

function formatCurrencyObject(object, formatted_name = false) {
  const keys = Object.keys(object);
  const response = {};
  keys.forEach((key) => {
    const formattedKey = formatted_name ? `${key}_formatted` : key;
    if (Number.isNaN(parseFloat(object[key]))) {
      response[formattedKey] = 'R$ 0,00';
      return response;
    }
    response[formattedKey] = formatCurrency(parseFloat(object[key] ?? 0));
  });
  return response;
}

const formatXmlResponseData = (data) => {
  const formattedData = {
    ...data,
    emitter: {
      ...data.emitter_attributes,
      cnpj: formatCnpjCpf(data.emitter_attributes.cnpj),
      zip_code: formatCep(data.emitter_attributes.zip_code),
    },
    recipient: {
      ...data.buyer_attributes,
      cnpj_cpf: formatCnpjCpf(data.buyer_attributes.cnpj_cpf),
      zip_code: formatCep(data.buyer_attributes.zip_code),
    },
    totalizer: {
      ...formatCurrencyObject(data.tax_attributes),
      total_note: formatCurrency(parseFloat(data.total_note ?? 0)),
      total_freight: formatCurrency(parseFloat(data.total_freight ?? 0)),
      total_products: formatCurrency(parseFloat(data.total_products ?? 0)),
      total_discount: formatCurrency(parseFloat(data.total_discount ?? 0)),
      total_other: formatCurrency(parseFloat(data.total_other ?? 0)),
    },
    transporter: {
      ...data.transporter_attributes,
      cnpj_cpf: formatCnpjCpf(data.transporter_attributes.cnpj) ?? '',
    },
    bill: {
      ...data.bill,
      duplicates: data.bill.duplicates.map((item) => ({
        number: item.number,
        due_date: formatDate(item.due_date),
        value: formatCurrency(item.value),
      })),
    },
    products: data.items.map((item) => ({
      id: ulid(),
      invoice_product_code: item.code,
      invoice_product_name: item.name,
      comercial_quantity: typeof item.quantity === 'string' ? parseFloat(item.quantity ?? 0) : item.quantity,
      unit_value: parseFloat(item.unit_value ?? 0),
      total_product_value: parseFloat(item.total_product_value ?? 0),
      unit_value_formatted: formatCurrency(parseFloat(item.unit_value ?? 0)),
      total_product_value_formatted: formatCurrency(parseFloat(item.total_product_value ?? 0)),
      discount_value: formatCurrency(parseFloat(item.discount_value ?? 0)),
      amount: parseFloat(item.amount),
      measuring_unit: item.measuring_unit,
      barcode: item.barcode,
      ncm: item.ncm,
      cest: item.cest,
      cfop: item.cfop,
      cofins: formatCurrencyObject(item.cofins, true),
      icms: formatCurrencyObject(item.icms, true),
      ipi: formatCurrencyObject(item.ipi, true),
      pis: formatCurrencyObject(item.pis, true),
    })),
    emission_date: formatDate(data.emission_date, true),
    date_hour_exit_entry: formatDate(data.exit_date ?? data.entry_date, true),
  };
  return formattedData;
};

const Invoices = DynamicService('invoices', {
  formatResponse,
  formatRequest,
});

const InvoicesCommands = ($axios) => ({
  async action(payload) {
    const res = await $axios.post('invoices/nfe/action.json', payload);
    return res;
  },

  async createOrder(id) {
    const res = await $axios.post(`invoices/nfe/create_orders/${id}`);
    return res;
  },

  async status() {
    const res = await $axios.post('invoices/nfe/status.json');
    return res;
  },

  async transmit(payload) {
    if (payload?.invoices?.length > 0) {
      const res = await $axios.post('invoices/nfe/transmit.json', payload);
      return res;
    }
  },

  async download(id) {
    const payload = { id };
    const res = await $axios.post('invoices/nfe/download.json', payload);
    return res;
  },

  async downloadCancellation(id) {
    const payload = { id };
    const res = await $axios.post('invoices/nfe/download_cancellation.json', payload);
    return res;
  },

  async downloadDisabled(id) {
    const payload = { id };
    const res = await $axios.post('invoices/nfe/download_disabled.json', payload);
    return res;
  },

  async uploadXMLFile(payload) {
    const formData = new FormData();
    formData.append('file', payload.file);
    formData.append('nature_operation_id', payload.nature_operation_id);
    formData.append('invoice_setting_id', payload.invoice_setting_id);
    formData.append('document_type', payload.document_type);
    const res = await $axios.post('invoices/mirror.json', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    formatResponse(res.data);
    return res.data;
  },

  async getXmlFileData(payload) {
    const formData = new FormData();
    formData.append('file', payload.file);
    const res = await $axios.post('invoices/mirror/parse_xml.json', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const formatted = formatXmlResponseData(res.data);
    return formatted;
  },

  async createOrders(payload) {
    const formData = new FormData();
    formData.append('file', payload.file);
    formData.append('nature_operation_id', payload.nature_operation_id);
    formData.append('invoice_setting_id', payload.invoice_setting_id);
    formData.append('document_type', payload.document_type);

    const res = await $axios.post('invoices/mirror/create_orders.json', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
  },

  async unbind(id) {
    const res = await $axios.post(`invoices/${id}/unbind_order.json`);
    return res;
  },

  async downloadBase64(id) {
    const payload = { id };
    const res = await $axios.post('invoices/nfe/download_base64.json', payload);
    return res?.data || '';
  },

  async downloadBase64Correction(id) {
    const payload = { id };
    const res = await $axios.post('invoices/nfe/download_base64_cce.json', payload);
    return res?.data || '';
  },

  async downloadXMLPDFBase64(id) {
    const payload = { id };
    const res = await $axios.post('invoices/nfe/generate_xml_pdf_base64.json', payload);
    return res?.data;
  },

  async validate(id) {
    const payload = { id };
    const res = await $axios.post('invoices/nfe/validate.json', payload);
    return res?.data || '';
  },

  async downloadValidateBase64(key) {
    const payload = { key };
    const res = await $axios.post('invoices/nfe/download_validate_base64.json', payload);
    return res?.data || '';
  },
});

const InvoicesPickupDelivery = () => {
  async function setDeliveryFields(formValue, id, address) {
    const logistic = { ...formValue.delivery } || {};
    address = formValue.delivery.address_id;
    await Vue.prototype.$api.Registers.show(id).then((response) => {
      const deliveryInfo = response;
      if (deliveryInfo.addresses && deliveryInfo.addresses.length > 0) {
        deliveryInfo.addresses.forEach((element) => {
          if (element.id === address) {
            logistic.address_id = element.id;
            logistic.zip_code = formatCep(element.zip_code);
            logistic.uf = element.uf;
            logistic.country = element.country.name;
            logistic.country_code = element.country.code;
            logistic.street = element.street;
            logistic.number = element.number;
            logistic.city = element.city;
            logistic.city_code = element.ibge;
            logistic.neighborhood = element.neighborhood;
            logistic.complement = element.complement;
          }
        });
      }
      const ieMask = getMaskText('state_inscription_mask', logistic.uf);
      logistic.social_reason = deliveryInfo.to_s;
      logistic.cnpj_cpf = formatCnpjCpf(deliveryInfo.cnpj_cpf);
      logistic.type_logistic = 'delivery';
      logistic.ie = replaceMask(deliveryInfo.ie_rg, ieMask);
    });

    return logistic;
  }

  async function setPickupFields(formValue, id, address) {
    const logistic = { ...formValue.pickup } || {};
    address = formValue.pickup.address_id;
    await Vue.prototype.$api.Companies.show(id).then((response) => {
      const pickupInfo = response;
      if (pickupInfo.addresses && pickupInfo.addresses.length > 0) {
        pickupInfo.addresses.forEach((element) => {
          if (element.id === address) {
            logistic.address_id = element.id;
            logistic.zip_code = formatCep(element.zip_code);
            logistic.uf = element.uf;
            logistic.street = element.street;
            logistic.country = element.country.name;
            logistic.country_code = element.country.code;
            logistic.number = element.number;
            logistic.city = element.city;
            logistic.city_code = element.ibge;
            logistic.neighborhood = element.neighborhood;
            logistic.complement = element.complement;
          }
        });
        const ieMask = getMaskText('state_inscription_mask', logistic.uf);
        logistic.social_reason = pickupInfo.to_s;
        logistic.cnpj_cpf = formatCnpjCpf(pickupInfo.cnpj);
        logistic.type_logistic = 'pickup';
        logistic.ie = replaceMask(pickupInfo.ie, ieMask);
      }
    });

    return logistic;
  }

  async function setDeliveryContactFields(formValue, id, contact) {
    // eslint-disable-next-line
    var { email, telephone } = formValue.delivery || {};
    await Vue.prototype.$api.Registers.show(id)
      .then((response) => {
        const deliveryContacts = response.contacts;
        deliveryContacts.forEach((element) => {
          if (element.id === contact) {
            email = element.email;
            telephone = element.telephone;
          }
        });
      })
      .catch((e) => {
        const errorMsg = e.response.data.errors.base;
        this.$notifyError(`${errorMsg.toString()}`);
      });

    return { email, telephone };
  }

  async function setPickupContactFields(formValue, id, contact) {
    // eslint-disable-next-line
    var { email, telephone } = formValue.pickup || {};
    await Vue.prototype.$api.Companies.show(id)
      .then((response) => {
        const pickupContacts = response.contacts;
        pickupContacts.forEach((element) => {
          if (element.id === contact) {
            email = element.email;
            telephone = element.telephone;
          }
        });
      })
      .catch((e) => {
        const errorMsg = e.response.data.errors.base;
        this.$notifyError(`${errorMsg.toString()}`);
      });

    return { email, telephone };
  }

  function mapContacts(contacts) {
    if (!contacts) return [];
    return contacts.map((contact) => ({
      text: contact.name || '',
      value: contact.id,
      ...contact,
    }));
  }

  function mapAddresses(addresses) {
    if (!addresses) return [];
    return addresses.map((address) => {
      const hasType = address.type_address;
      const typeAddres = hasType ? address.type_address.to_s : '';
      return {
        text: address.main ? `${typeAddres}` : `${typeAddres} - Auxiliar`,
        value: address.id,
        ...address,
      };
    });
  }

  const deliveryAddresses = async (formValue) => {
    const buyerId = formValue.buyer.register_id;
    let res;
    if (buyerId) {
      await Vue.prototype.$api.Registers.show(buyerId).then((response) => {
        const { addresses } = response;
        const deliveryAddressesMap = mapAddresses(addresses) || [];
        res = deliveryAddressesMap;
      });
    }
    return res || [];
  };

  const deliveryContacts = async (formValue) => {
    const buyerId = formValue.buyer.register_id;
    let res;
    if (buyerId) {
      await Vue.prototype.$api.Registers.show(buyerId).then((response) => {
        const { contacts } = response;
        const deliveryContactsMap = mapContacts(contacts) || [];
        res = deliveryContactsMap;
      });
    }
    return res || [];
  };

  const pickupAddresses = async (formValue) => {
    const companyId = formValue.emitter.company_id;
    let res;
    if (companyId) {
      await Vue.prototype.$api.Companies.show(companyId).then((response) => {
        const { addresses } = response;
        const pickupAddressesMap = mapAddresses(addresses) || [];
        res = pickupAddressesMap;
      });
    }
    return res || [];
  };

  const pickupContacts = async (formValue) => {
    const companyId = formValue.emitter.company_id;
    let res;
    if (companyId) {
      await Vue.prototype.$api.Companies.show(companyId).then((response) => {
        const { contacts } = response;
        const pickupContactsMap = mapContacts(contacts) || [];
        res = pickupContactsMap;
      });
    }
    return res || [];
  };

  return {
    setDeliveryFields,
    setPickupFields,
    setDeliveryContactFields,
    setPickupContactFields,
    pickupAddresses,
    pickupContacts,
    deliveryAddresses,
    deliveryContacts,
  };
};

export default { Invoices, InvoicesCommands, InvoicesPickupDelivery };
export { Invoices, InvoicesCommands, InvoicesPickupDelivery };
