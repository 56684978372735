import Admins from './pages/admins/routes';
import Companies from './pages/companies/routes';
import CompanyPreferences from './pages/companypreferences/routes';
import Cnabs from './pages/cnabs/routes';
import Help from './pages/help/routes';
import EmailLayouts from './pages/emaillayouts/routes';
import EmailSentSystem from './pages/emailsentsystem/routes';
import InvoiceTemplates from './pages/invoicetemplates/routes';
import Profiles from './pages/profiles/routes';
import Subscriptions from './pages/subscriptions/routes';
import Users from './pages/users/routes';

export default [
  ...Admins,
  ...Cnabs,
  ...Companies,
  ...CompanyPreferences,
  ...Help,
  ...EmailLayouts,
  ...EmailSentSystem,
  ...InvoiceTemplates,
  ...Profiles,
  ...Subscriptions,
  ...Users,
];
